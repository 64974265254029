@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap");
body {
  font-family: "Inter", "Helvetica Neue", "Helvetica", "Arial", "sans-serif";
}

:root {
  --color-primary: #cd1b49;
  --font-family: Inter, Helvetica Neue, Helvetica, Arial, sans-serif;
}

