@use 'sass:map';
@use '../../styles/libs/sassbox';
@use '../../styles/config';

.host {
  position: relative;
  @include sassbox.reset-button();
  font-family: var(--font-family);
  @include sassbox.use-type('xs', $set-line-height: false);
  text-transform: uppercase;
  @include config.text-neg();
  padding: 0 0 0 20px;
  cursor: pointer;

  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 45%;
    transform: translateY(-50%);
    width: 14px;
    height: 14px;
    background-image: url('../../images/shared/arrow-left.svg');
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: 100% auto;
  }

  &::after {
    content: '';
    width: 0;
    height: 2px;
    background-color: map.get(config.$kq-colors, 'black');
    position: absolute;
    bottom: -4px;
    left: 0;
    transition: width 0.3s;
  }

  &:hover::after {
    width: 100%;
  }
}
