@use 'sass:map';
@use 'sass:math';
@use '../../styles/libs/sassbox';
@use '../../styles/config';
@use '../../styles/utils';

.nav {
  display: none;
  position: fixed;
  z-index: map.get(config.$kq-z-indexes, 'nav');
  right: 24px;
  bottom: 24px;
  left: 24px;
  color: map.get(config.$kq-colors, 'white');
  background-color: var(--color-primary);
  @include config.text-neg();
}

.wrapper {
  width: 100%;
  height: 100%;
  padding: 24px;
  overflow: auto;
}

.section {
  width: 100%;
  margin-top: 24px;

  &:first-of-type {
    margin-top: 0;
  }
}

.title {
  margin: 0;
  @include sassbox.use-type('m');
}

.item {
  margin: 0;
  @include sassbox.use-type('m');
}

.link {
  color: map.get(config.$kq-colors, 'white');
  text-decoration-color: rgba(map.get(config.$kq-colors, 'white'), config.$link-decoration-opacity);
}
