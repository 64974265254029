.host {
  position: relative;
  border: 0;
  outline: none;
  appearance: none;
  background-color: transparent;
  font-family: var(--font-family);
  font-size: 0.875rem;
  text-transform: uppercase;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  letter-spacing: 0.03em;
  padding: 0 0 0 20px;
  cursor: pointer;
}
.host::before {
  content: "";
  position: absolute;
  left: 0;
  top: 45%;
  transform: translateY(-50%);
  width: 14px;
  height: 14px;
  background-image: url("../../images/shared/arrow-left.svg");
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100% auto;
}
.host::after {
  content: "";
  width: 0;
  height: 2px;
  background-color: #000;
  position: absolute;
  bottom: -4px;
  left: 0;
  transition: width 0.3s;
}
.host:hover::after {
  width: 100%;
}

