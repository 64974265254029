@use '../../styles/libs/sassbox';
@use '../../styles/config';
@use '../../styles/utils';

.page {
  margin-bottom: 24px;
}

.key-image {
  margin-bottom: 24px;
}

.title {
  margin-top: 0;
  @include config.vertical-margin('xl');
  @include sassbox.use-type('xl');
}

.richtext {
  h2 {
    @include config.vertical-margin('l');
    @include sassbox.use-type('l');
  }

  h3 {
    @include config.vertical-margin('m');
    @include sassbox.use-type('m');
  }

  h4 {
    font-weight: normal;
    text-decoration: underline;
    @include config.vertical-margin('m');
    @include sassbox.use-type('m');
  }

  h5,
  h6 {
    font-weight: normal;
    @include config.vertical-margin('m');
    @include sassbox.use-type('m');
  }

  p,
  li {
    @include config.vertical-margin('m');
    @include sassbox.use-type('m');
  }

  li {
    position: relative;
    padding-left: 1em;
    @include config.vertical-margin('m');

    &::before {
      content: '\2013';
      display: inline-block;
      width: 1em;
      margin-left: -1em;
    }
  }

  a {
    color: var(--color-primary);
  }
}
