.title {
  margin-top: 0;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  font-size: 1.5rem;
  line-height: 2.1rem;
}
@media (min-width: 36em) {
  .title {
    font-size: 1.875rem;
    line-height: 2.4375rem;
  }
}
@media (min-width: 48em) {
  .title {
    font-size: 2.5rem;
    line-height: 3rem;
  }
}

