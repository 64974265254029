.enter {
  display: block;
  opacity: 0;
  transform: scaleY(0);
}

.enterActive {
  opacity: 1;
  transform: scaleY(1);
  transition: opacity 0.3s, transform 0.3s, top 0.3s;
}

.enterDone {
  display: block;
}

.exit {
  display: block;
  opacity: 1;
  transform: scaleX(1);
}

.exitActive {
  opacity: 0;
  transform: scaleX(0);
  transition: opacity 0.3s, transform 0.3s, top 0.3s;
}

.exitDone {
  display: none;
}

