.kq-lazyfade--appear {
  opacity: 0;
  transform: translateY(50px);
}

.kq-lazyfade--appear-active {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 1s, transform 1s;
}

