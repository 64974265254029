.ressource {
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  font-size: 1.125rem;
  line-height: 1.575rem;
}
@media (min-width: 36em) {
  .ressource {
    font-size: 1.5rem;
    line-height: 2.1rem;
  }
}
.ressource a {
  color: var(--color-primary);
}

