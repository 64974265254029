@use 'styles/libs/sassbox';

// Reset, normalize or unify browser rendering

@include sassbox.normalize();

// Use display:block for images
img {
  border: none;
}

// Ensure link underlines do not strike-through text
a {
  text-underline-position: under;
}

img {
  max-width: 100%;
  height: auto;
}
