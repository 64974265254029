.nav {
  display: none;
  position: fixed;
  z-index: 90;
  right: 24px;
  bottom: 24px;
  left: 24px;
  color: #fff;
  background-color: var(--color-primary);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  letter-spacing: 0.03em;
}

.wrapper {
  width: 100%;
  height: 100%;
  padding: 24px;
  overflow: auto;
}

.section {
  width: 100%;
  margin-top: 24px;
}
.section:first-of-type {
  margin-top: 0;
}

.title {
  margin: 0;
  font-size: 1.125rem;
  line-height: 1.575rem;
}
@media (min-width: 36em) {
  .title {
    font-size: 1.5rem;
    line-height: 2.1rem;
  }
}

.item {
  margin: 0;
  font-size: 1.125rem;
  line-height: 1.575rem;
}
@media (min-width: 36em) {
  .item {
    font-size: 1.5rem;
    line-height: 2.1rem;
  }
}

.link {
  color: #fff;
  text-decoration-color: rgba(255, 255, 255, 0.5);
}

