@charset "UTF-8";
.page {
  margin-bottom: 24px;
}

.key-image {
  margin-bottom: 24px;
}

.title {
  margin-top: 0;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  font-size: 1.5rem;
  line-height: 2.1rem;
}
@media (min-width: 36em) {
  .title {
    font-size: 1.875rem;
    line-height: 2.4375rem;
  }
}
@media (min-width: 48em) {
  .title {
    font-size: 2.5rem;
    line-height: 3rem;
  }
}

.richtext h2 {
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  font-size: 1.125rem;
  line-height: 1.575rem;
}
@media (min-width: 36em) {
  .richtext h2 {
    font-size: 1.5rem;
    line-height: 2.1rem;
  }
}
@media (min-width: 62em) {
  .richtext h2 {
    font-size: 1.875rem;
    line-height: 2.4375rem;
  }
}
.richtext h3 {
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  font-size: 1.125rem;
  line-height: 1.575rem;
}
@media (min-width: 36em) {
  .richtext h3 {
    font-size: 1.5rem;
    line-height: 2.1rem;
  }
}
.richtext h4 {
  font-weight: normal;
  text-decoration: underline;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  font-size: 1.125rem;
  line-height: 1.575rem;
}
@media (min-width: 36em) {
  .richtext h4 {
    font-size: 1.5rem;
    line-height: 2.1rem;
  }
}
.richtext h5,
.richtext h6 {
  font-weight: normal;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  font-size: 1.125rem;
  line-height: 1.575rem;
}
@media (min-width: 36em) {
  .richtext h5,
  .richtext h6 {
    font-size: 1.5rem;
    line-height: 2.1rem;
  }
}
.richtext p,
.richtext li {
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  font-size: 1.125rem;
  line-height: 1.575rem;
}
@media (min-width: 36em) {
  .richtext p,
  .richtext li {
    font-size: 1.5rem;
    line-height: 2.1rem;
  }
}
.richtext li {
  position: relative;
  padding-left: 1em;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}
.richtext li::before {
  content: "–";
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}
.richtext a {
  color: var(--color-primary);
}

