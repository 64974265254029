@use 'sass:map';
@use '../config';

// Fonts

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap');

body {
  font-family: map.get(config.$kq-fonts, 'sans-neuefreistatt');
}
