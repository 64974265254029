@use 'sass:map';
@use '../../styles/libs/sassbox';
@use '../../styles/config';
@use '../../styles/utils';

.group {
  display: flex;
}

.input {
  display: inline-block;
  font-family: var(--font-family);
  padding: 0.5em;
  margin: 0;
  border-radius: 0;
  @include sassbox.reset-button();
  @include sassbox.use-type('m', $set-line-height: false);
}

.input--email {
  flex-grow: 3;
  border: 1px solid var(--color-primary);
  border-right-width: 0;
}

.input--submit {
  flex-grow: 1;
  text-transform: uppercase;
  @include config.text-neg();
  background-color: var(--color-primary);
  color: map.get(config.$kq-colors, 'white');
  cursor: pointer;
}

.message {
  margin-top: 1.5em;
  padding: 24px;
  color: map.get(config.$kq-colors, 'white');
  background-color: var(--color-primary);
  @include utils.font-smoothing();
  @include sassbox.use-type('m');

  & a {
    color: map.get(config.$kq-colors, 'white');
  }
}

.message--error {
  padding: 24px;
  color: map.get(config.$kq-colors, 'black');
  background-color: map.get(config.$kq-colors, 'yellow');
  @include utils.font-smoothing($font-smoothing: 'auto');

  & a {
    color: map.get(config.$kq-colors, 'black');
  }
}
