.map-frame {
  margin-top: 24px;
  padding: 24px;
  max-width: 1200px;
  background-color: #fff;
}

.map {
  margin-top: 24px;
  padding-bottom: 95.9059233449%;
  background-image: url("../../images/layout/Nav/map-s.gif");
  background-size: 100% auto;
}

@media (min-width: 48em) {
  .map {
    padding-bottom: 61.7107942974%;
    background-image: url("../../images/layout/Nav/map-l.jpg");
  }
}

