@use 'sass:math';
@use '../../styles/libs/sassbox';
@use '../../styles/config';
@use '../../styles/utils';

.blog-post {
  margin-bottom: 24px;
}

.back {
  display: inline-block;
  margin-bottom: 24px;
}

.title {
  margin-top: 0;
  margin-bottom: 0.5em;
  @include sassbox.use-type('xl');
}

.teaser {
  @include config.vertical-margin('l');
  @include sassbox.use-type('l');
}

.image-credits {
  a {
    color: var(--color-primary);
  }
}

.richtext {
  h2 {
    @include config.vertical-margin('l');
    @include sassbox.use-type('l');
  }

  h3 {
    @include config.vertical-margin('m');
    @include sassbox.use-type('m');
  }

  h4 {
    font-weight: normal;
    text-decoration: underline;
    @include config.vertical-margin('m');
    @include sassbox.use-type('m');
  }

  h5,
  h6 {
    font-weight: normal;
    @include config.vertical-margin('m');
    @include sassbox.use-type('m');
  }

  p,
  li {
    @include config.vertical-margin('m');
    @include sassbox.use-type('m');
  }

  li {
    position: relative;
    padding-left: 1em;
    @include config.vertical-margin('m');

    &::before {
      content: '\2013';
      display: inline-block;
      width: 1em;
      margin-left: -1em;
    }
  }

  a {
    color: var(--color-primary);
  }

  .block-img img {
    width: 100%;
    height: auto;
  }

  // Force video proportions 16:10
  [data-oembed-type='video'] {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: math.div(10, 16) * 100%;
    @include config.vertical-margin('m');
    @include sassbox.use-type('m');

    & > * {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }
  }
}
