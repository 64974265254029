@use 'sass:map';
@use '../../styles/config';

.footer {
  margin-top: 24px;
}

.version {
  position: absolute;
  right: 0;
  top: 4px;
  display: block;
  font-size: 9px;
  opacity: 0.5;
}

.logos {
  height: 74px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid map.get(config.$kq-colors, 'black');
  padding-top: 24px;
  margin-bottom: 24px;
}

.link {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 25%;
  height: 100%;
}

.logo {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
