@use 'sass:map';
@use '../../styles/libs/sassbox';
@use '../../styles/config';
@use '../../styles/utils';

.event {
  margin-bottom: 24px;
}

.back {
  display: inline-block;
  margin-bottom: 24px;
}

.key-image {
  margin-bottom: 24px;
}

.image-credits {
  @include sassbox.use-type('s');

  p {
    margin: 0 0 18px;
  }

  a {
    color: var(--color-primary);
  }
}

.title {
  margin-top: 0;
  margin-bottom: 0.5em;
  @include sassbox.use-type('xl');
}

.teaser {
  @include config.vertical-margin('l');
  @include sassbox.use-type('l');
}

.meta {
  border-top: 1px solid map.get(config.$kq-colors, 'black');
  margin: 1.5em 0 1.7em;
  @include sassbox.use-type('m', $set-line-height: false);
}

.time,
.location {
  display: block;
  position: relative;
  padding: 0.5em 0 0.5em 24px;
  border-bottom: 1px solid map.get(config.$kq-colors, 'black');

  & p {
    margin: 0;
  }

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    width: 16px;
    height: 16px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100% auto;
    transform: translateY(-50%);
  }
}

.time::before {
  background-image: url('../../images/pages/Event/clock.svg');
}

.location::before {
  background-image: url('../../images/pages/Event/location.svg');
}

.richtext {
  h2 {
    @include config.vertical-margin('l');
    @include sassbox.use-type('l');
  }

  h3 {
    @include config.vertical-margin('m');
    @include sassbox.use-type('m');
  }

  h4 {
    font-weight: normal;
    text-decoration: underline;
    @include config.vertical-margin('m');
    @include sassbox.use-type('m');
  }

  h5,
  h6 {
    font-weight: normal;
    @include config.vertical-margin('m');
    @include sassbox.use-type('m');
  }

  p,
  li {
    @include config.vertical-margin('m');
    @include sassbox.use-type('m');
  }

  li {
    position: relative;
    padding-left: 1em;
    @include config.vertical-margin('m');

    &::before {
      content: '\2013';
      display: inline-block;
      width: 1em;
      margin-left: -1em;
    }
  }

  a {
    color: var(--color-primary);
  }

  .block-img img {
    width: 100%;
    height: auto;
  }
}
