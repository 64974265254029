@charset "UTF-8";
.quote {
  text-align: center;
}

.author-portrait {
  margin: 0 auto 0.3333333333em;
  background-position: center;
  background-size: cover;
  border-radius: 50%;
  width: 20%;
  padding-bottom: 20%;
  font-size: 1.125rem;
}
@media (min-width: 36em) {
  .author-portrait {
    font-size: 1.5rem;
  }
}
@media (min-width: 48em) {
  .author-portrait {
    margin-bottom: 0.8em;
    width: 15%;
    padding-bottom: 15%;
  }
}

.text {
  margin: 0;
  color: var(--color-primary);
  font-size: 1.5rem;
  line-height: 2.1rem;
}
@media (min-width: 36em) {
  .text {
    font-size: 1.875rem;
    line-height: 2.4375rem;
  }
}
@media (min-width: 48em) {
  .text {
    font-size: 2.5rem;
    line-height: 3rem;
  }
}
.text::before {
  content: "«";
}
.text::after {
  content: "»";
}

.author {
  margin: 0.3333333333em 0 0;
  font-size: 1.125rem;
}
@media (min-width: 36em) {
  .author {
    font-size: 1.5rem;
  }
}

