.group {
  display: flex;
}

.input {
  display: inline-block;
  font-family: var(--font-family);
  padding: 0.5em;
  margin: 0;
  border-radius: 0;
  border: 0;
  outline: none;
  appearance: none;
  background-color: transparent;
  font-size: 1.125rem;
}
@media (min-width: 36em) {
  .input {
    font-size: 1.5rem;
  }
}

.input--email {
  flex-grow: 3;
  border: 1px solid var(--color-primary);
  border-right-width: 0;
}

.input--submit {
  flex-grow: 1;
  text-transform: uppercase;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  letter-spacing: 0.03em;
  background-color: var(--color-primary);
  color: #fff;
  cursor: pointer;
}

.message {
  margin-top: 1.5em;
  padding: 24px;
  color: #fff;
  background-color: var(--color-primary);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1.125rem;
  line-height: 1.575rem;
}
@media (min-width: 36em) {
  .message {
    font-size: 1.5rem;
    line-height: 2.1rem;
  }
}
.message a {
  color: #fff;
}

.message--error {
  padding: 24px;
  color: #000;
  background-color: rgb(255, 220, 0);
  -webkit-font-smoothing: subpixel-antialiased;
  -moz-osx-font-smoothing: auto;
}
.message--error a {
  color: #000;
}

