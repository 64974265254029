@charset "UTF-8";
.event {
  margin-bottom: 24px;
}

.back {
  display: inline-block;
  margin-bottom: 24px;
}

.key-image {
  margin-bottom: 24px;
}

.image-credits {
  font-size: 1.125rem;
  line-height: 1.575rem;
}
.image-credits p {
  margin: 0 0 18px;
}
.image-credits a {
  color: var(--color-primary);
}

.title {
  margin-top: 0;
  margin-bottom: 0.5em;
  font-size: 1.5rem;
  line-height: 2.1rem;
}
@media (min-width: 36em) {
  .title {
    font-size: 1.875rem;
    line-height: 2.4375rem;
  }
}
@media (min-width: 48em) {
  .title {
    font-size: 2.5rem;
    line-height: 3rem;
  }
}

.teaser {
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  font-size: 1.125rem;
  line-height: 1.575rem;
}
@media (min-width: 36em) {
  .teaser {
    font-size: 1.5rem;
    line-height: 2.1rem;
  }
}
@media (min-width: 62em) {
  .teaser {
    font-size: 1.875rem;
    line-height: 2.4375rem;
  }
}

.meta {
  border-top: 1px solid #000;
  margin: 1.5em 0 1.7em;
  font-size: 1.125rem;
}
@media (min-width: 36em) {
  .meta {
    font-size: 1.5rem;
  }
}

.time,
.location {
  display: block;
  position: relative;
  padding: 0.5em 0 0.5em 24px;
  border-bottom: 1px solid #000;
}
.time p,
.location p {
  margin: 0;
}
.time::before,
.location::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  width: 16px;
  height: 16px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% auto;
  transform: translateY(-50%);
}

.time::before {
  background-image: url("../../images/pages/Event/clock.svg");
}

.location::before {
  background-image: url("../../images/pages/Event/location.svg");
}

.richtext h2 {
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  font-size: 1.125rem;
  line-height: 1.575rem;
}
@media (min-width: 36em) {
  .richtext h2 {
    font-size: 1.5rem;
    line-height: 2.1rem;
  }
}
@media (min-width: 62em) {
  .richtext h2 {
    font-size: 1.875rem;
    line-height: 2.4375rem;
  }
}
.richtext h3 {
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  font-size: 1.125rem;
  line-height: 1.575rem;
}
@media (min-width: 36em) {
  .richtext h3 {
    font-size: 1.5rem;
    line-height: 2.1rem;
  }
}
.richtext h4 {
  font-weight: normal;
  text-decoration: underline;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  font-size: 1.125rem;
  line-height: 1.575rem;
}
@media (min-width: 36em) {
  .richtext h4 {
    font-size: 1.5rem;
    line-height: 2.1rem;
  }
}
.richtext h5,
.richtext h6 {
  font-weight: normal;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  font-size: 1.125rem;
  line-height: 1.575rem;
}
@media (min-width: 36em) {
  .richtext h5,
  .richtext h6 {
    font-size: 1.5rem;
    line-height: 2.1rem;
  }
}
.richtext p,
.richtext li {
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  font-size: 1.125rem;
  line-height: 1.575rem;
}
@media (min-width: 36em) {
  .richtext p,
  .richtext li {
    font-size: 1.5rem;
    line-height: 2.1rem;
  }
}
.richtext li {
  position: relative;
  padding-left: 1em;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}
.richtext li::before {
  content: "–";
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}
.richtext a {
  color: var(--color-primary);
}
.richtext .block-img img {
  width: 100%;
  height: auto;
}

