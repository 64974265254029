html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

*:focus {
  outline: none;
}

html,
body {
  margin: 0;
  padding: 0;
}

body {
  -webkit-text-size-adjust: none;
}

img {
  display: block;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

blockquote {
  margin: 0;
}

table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

th {
  text-align: left;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

address {
  font-style: normal;
}

button,
input,
textarea {
  font-family: inherit;
}

svg {
  display: block;
}

fieldset {
  border: none;
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

img {
  border: none;
}

a {
  text-underline-position: under;
}

img {
  max-width: 100%;
  height: auto;
}

