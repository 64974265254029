@use 'sass:map';
@use 'sass:math';
@use '../../styles/libs/sassbox';
@use '../../styles/config';

.map-frame {
  margin-top: 24px;
  padding: 24px;
  max-width: 1200px;
  background-color: map.get(config.$kq-colors, 'white');
}

.map {
  margin-top: 24px;
  padding-bottom: math.div(1101, 1148) * 100%;
  background-image: url('../../images/layout/Nav/map-s.gif');
  background-size: 100% auto;
}

// Responsive layouts

@include sassbox.mq($from: 'rat') {
  .map {
    padding-bottom: math.div(1515, 2455) * 100%;
    background-image: url('../../images/layout/Nav/map-l.jpg');
  }
}
