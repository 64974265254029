.outer {
  position: relative;
  overflow: hidden;
  height: 0;
  width: 100%;
}

.inner {
  position: absolute;
  inset: 0;
}

.content {
  display: block;
  overflow: hidden;
  width: 100%;
  height: 100%;
}
