@use '../../styles/libs/sassbox';
@use '../../styles/config';

.title {
  @include config.vertical-margin('xl');
  @include sassbox.use-type('xl');
}

.richtext {
  margin-bottom: 1em;

  a {
    color: var(--color-primary);
  }

  p,
  li {
    @include config.vertical-margin('m');
    @include sassbox.use-type('m');
  }
}
