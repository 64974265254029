@use 'sass:map';
@use '../../styles/config';

// Full viewport width
.host {
  width: 100%;
}

// Full layout width (grid width incl. outer gaps)
.layout {
  position: relative;
  overflow: hidden;
  max-width: map.get(config.$kq-grid, 'full-width');
  margin: 0 auto;
}

// Inner width (grid width excl. outer gaps)
.inner {
  position: relative;
  margin: 0 map.get(config.$kq-grid, 'margin');
}
