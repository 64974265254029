@use '../utils';

// Typographic definitions

// Fonts
$kq-fonts: (
  'sans': (
    'Helvetica Neue',
    'Helvetica',
    'Arial',
    'sans-serif',
  ),
  'sans-kochquartier': (
    'GT-Walsheim',
    'Helvetica Neue',
    'Helvetica',
    'Arial',
    'sans-serif',
  ),
  'sans-neuefreistatt': (
    'Inter',
    'Helvetica Neue',
    'Helvetica',
    'Arial',
    'sans-serif',
  ),
  'sans-nordnordost': (
    'GT-Ultra-Standard',
    'Helvetica Neue',
    'Helvetica',
    'Arial',
    'sans-serif',
  ),
);

// Font sizes
$kq-font-size: (
  'xs': 14px,
  's': 18px,
  'm': 24px,
  'l': 30px,
  'xl': 40px,
  'xxl': 48px,
);

// Line heights
$kq-line-heights: (
  'xs': 19.6,
  's': 25.2,
  'm': 33.6,
  'l': 39,
  'xl': 48,
  'xxl': 57.6,
);

// 'Fluid' font sizes are scaled down for the given responsive breakpoints
$kq-fluid-type: (
  'm': (
    'default': 's',
    'mouse': 'm',
  ),
  'l': (
    'default': 's',
    'mouse': 'm',
    'rabbit': 'l',
  ),
  'xl': (
    'default': 'm',
    'mouse': 'l',
    'rat': 'xl',
  ),
  'xxl': (
    'default': 'm',
    'mouse': 'l',
    'rat': 'xl',
    'cat': 'xxl',
  ),
);

// Adjust typographic settings for bright text on darker background
@mixin text-neg() {
  @include utils.font-smoothing();
  letter-spacing: 0.03em;
}

// Ensure vertical margins are consistent across the app
@mixin vertical-margin($size: 'm') {
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}

// Link decoration opacity
$link-decoration-opacity: 0.5;
