.host {
  width: 100%;
}

.layout {
  position: relative;
  overflow: hidden;
  max-width: 1200px;
  margin: 0 auto;
}

.inner {
  position: relative;
  margin: 0 24px;
}

