@mixin font-smoothing($font-smoothing: 'sharp') {
  @if $font-smoothing == 'sharp' {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  } @else if $font-smoothing == 'none' {
    -webkit-font-smoothing: none;
  } @else {
    -webkit-font-smoothing: subpixel-antialiased;
    -moz-osx-font-smoothing: auto;
  }
}
