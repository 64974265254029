@use '../../styles/libs/sassbox';
@use '../../styles/config';
@use '../../styles/utils';

.ressource {
  @include config.vertical-margin('m');
  @include sassbox.use-type('m');

  a {
    color: var(--color-primary);
  }
}
