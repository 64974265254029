@use 'sass:map';
@use '../../styles/libs/sassbox';
@use '../../styles/config';
@use '../../styles/utils';

.key-visual {
  margin-bottom: 24px;
}

.caption {
  @include sassbox.use-type('s');

  p {
    margin: 0 0 18px;
  }

  a {
    color: map.get(config.$kq-colors, 'black');
  }
}
