.key-visual {
  margin-bottom: 24px;
}

.caption {
  font-size: 1.125rem;
  line-height: 1.575rem;
}
.caption p {
  margin: 0 0 18px;
}
.caption a {
  color: #000;
}

