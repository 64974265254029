@use 'sass:math';
@use '../../styles/libs/sassbox';
@use '../../styles/config';

.quote {
  text-align: center;
}

.author-portrait {
  margin: 0 auto (math.div(6, 18) * 1em);
  background-position: center;
  background-size: cover;
  border-radius: 50%;
  width: 20%;
  padding-bottom: 20%;
  @include sassbox.use-type('m', $set-line-height: false);

  @include sassbox.mq($from: 'rat') {
    margin-bottom: 0.8em;
    width: 15%;
    padding-bottom: 15%;
  }
}

.text {
  margin: 0;
  color: var(--color-primary);
  @include sassbox.use-type('xl');

  &::before {
    content: '«';
  }
  &::after {
    content: '»';
  }
}

.author {
  margin: (math.div(6, 18) * 1em) 0 0;
  @include sassbox.use-type('m', $set-line-height: false);
}
